.description{
    font-weight: 600;
    font-size: 12px;
    color: rgba(100, 100, 100, 0.808);
    text-align: center;
    height: 70px;
}
.image-food{
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    height: 200px;
}
.heading{
    font-weight: 500;
    font-size: 21pt;
    font-family: 'Kaushan Script';
    color: #FFF;
    padding: 10px;
    background: #222D42;
    width: 30%;
    border-radius: 10px;
    margin-bottom: 30px;
}
.backButton{
    color: #FFF;
    padding: 10px;
    background: #222D42;
    width: 50px !important;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
    cursor: pointer;
}
.backButton:hover{
    opacity: 0.9;
}
.heading-container{
    display: grid;
    width: 100%;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
    
}
.title{
    font-weight: 900;
    font-size: 12pt;
    text-align: center;
    color: #EF791F;    
    height: 52px;
}
.price{
    font-weight: 900;
    font-size: 14pt;
    color: #F7A93B;
    padding-top: 10px;
    
}
.btn-ekle{
    font-weight: 800!important;
    font-size: 12pt!important;
    color: #fefefe !important;
    background-color: #F7A93B !important;
    margin-right: auto;
    margin-left: auto;
    display: flex;
}
.btn-ekle:hover,
.btn-ekle:active{
    font-weight: 900!important;
    font-size: 13pt!important;
    color: #fefefe !important;
    background-color: #EF791F !important;
}

.menu-col .card{
    min-width: 80px !important;
    max-width: 300px  !important;
    cursor: pointer;
}


@media screen and (max-width: 1444px) {
    .title{
        font-size: 20px;
        margin: 0;
    }
    .description{
        margin: 0;
    }
}
.price-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.price{
    padding: 0 !important;
    margin: 0;
    text-align: center;
}
@media screen and (max-width: 996px) {
    .image-food{
        height: 190px;
    }
    .description{
        /* display: none; */
        text-align: center;
        padding: 10px;
    }
    .title{
        font-size: 16px;
        text-align: center;
    }
    .heading{
        font-size: 18pt;    
        width: 40%;
    }
    .scrollmenu a{
        font-size: 14px;
    }
    .menu-col .card{
        max-width: 200px  !important;
    }

}
@media screen and (max-width: 660px) {
    .heading{
        font-size: 16pt;    
        width: 60%;
    }
    .image-food{
        height: 130px;
    }
    .title{
        font-size: 14px;
    }
    .menu-col .card{
        max-width: 160px  !important;
    }
}
@media screen and (max-width:350px) {
    .image-food{
        height: 110px;
    }
    .heading{
        font-size: 16pt;    
        width: 70%;
    }
    .title{
        font-size: 14px;
    }
    .description{
        font-size: 12px;
        height: 40px;

    }
    .menu-col .card{
        max-width: 135px  !important;
    }
    .scrollmenu a{
        font-size: 10px;
    }

}
.col-teezy{
    justify-content: center;
    display: flex;
}