:root {
  --clr-primary: #EF791F;
  --clr-secondary: #272d36;
  height: 100%;
  --size: 50px;
  --spinnerWidth:0.6;
  --color:#EF791F;
  --maskColor: #161616;
  --animTime: 2.25s;
  --easing: ease;  
  --timing: --easing;

}
#root{
  height: 100%;

}
html {
  overscroll-behavior: none;
  height: 100vh;

}
body{
  height: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
}
.section {
  width: 100%;
  padding: 40px 0;
  height: 100%;

}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  padding: 0 30px;
}

.primary {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 20px;
}

.secondary {
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 20px;
}

.tertiary {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}

.btn {
  padding: 14px 20px;
  background: var(--clr-primary);
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  font-size: 22px;
  display: inline-block;
  margin: 20px 0;
}

::-webkit-scrollbar {
	width: 6px;
	background-color: #FFFBF6;

}
::-webkit-scrollbar-thumb {
	background-color: #EF791F;
}


.spinner-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
