.list-group-item.active {
    background-color: #EF791F !important;
    border-color:  #EF791F !important;
}
.menu-row{
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/4438226.png'); 
    background-size: cover;
    background-repeat: repeat, no-repeat;
    background-position: center;
    background-attachment: fixed;  
}

.menu-col{
  padding: 0.5rem;
  margin: 0.5rem;
}
div.scrollmenu {
    background-color: #222D42;
    overflow: auto;
    white-space: nowrap;
    cursor: pointer;
    width: 100%; /* Full width */
    transition: top 0.3s; /* Transition effect when sliding down (and up) */
    -webkit-box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
    box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
  }
  
  div.scrollmenu a {
    display: inline-block;
    color: #fffbf6  !important;
    text-align: center;
    padding: 14px;
    text-decoration: none;
    font-family: 'Kaushan Script', cursive;
    font-weight: 700 !important;
  }
  .asdfa{
    padding-top: 100px; /* This should be equal to the height of your header */
  }

  .extraspace div {
    scroll-margin-top: 150px; /* Adjust this value based on the height of your sticky navbar */
  }
  .extraspace #menu{
    scroll-margin-top: 200px; /* Adjust this value based on the height of your sticky navbar */

  }
  
  div.scrollmenu a:hover {
    background-color: #EF791F !important;
    border-color:  #EF791F !important;
    color: #FFF;

 }
 
.scrollToTopBtn {
    background-color: black;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px;
    line-height: 48px;
    width: 48px;  
    /* place it at the bottom right corner */
    position: fixed;
    bottom: 20px;
    right: 20px;
    /* keep it at the top of everything else */
    z-index: 100;
    /* hide with opacity */
    opacity: 0;
    /* also add a translate effect */
    transform: translateY(100px);
    /* and a transition */
    transition: all 0.5s ease;
  }
  
  .showBtn {
    opacity: 1;
    transform: translateY(0);
  }

  /* The sticky class is added to the navbar with JS when it reaches its scroll position */
  .sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  /* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .content {
    padding-top: 60px;
  }
  .back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 50px !important;
  }
 .scrollmenu::-webkit-scrollbar{
  display: none;
 }

 .menu-col .row{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
  gap: 5px; 

}

@media screen and (max-width:996px) {


  .menu-col .row{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    gap: 20px; 
 }
}
@media screen and (max-width:660px) {
  .menu-col .row{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); /* Responsive grid */
    gap: 20px; 
 }
}
 
 

@media screen and (max-width:405px) {
  .menu-col .row{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr)); /* Responsive grid */
    gap: 20px; 
 }
}
#gallery {
  & b {
    font-family: 'Kaushan Script', cursive !important;
    opacity: 0.9;
  }
}

#gallery {
  margin: 0 auto;
  padding-inline: 8vw;
  padding-top: 5vh;
  font-size: 1.6rem;
  h1 { 
     font-weight: normal;       
     color: #fffbf6;
     text-align: center;
  }
  b { font-weight: bold; }
  .title{
    font-size: 1.2rem;

  }
  #header {
    position: relative;
    height: 12vh;
    line-height: 12vh;
    padding: 0 3vw;
    font-size: 5vh;
    font-weight: 900;
    text-align: center;
    
    #logo {
      font-size: 2.5vh;
      font-style: italic;
      font-size: 5vh;
      color: #222D42;
      font-weight: 900;
      text-align: center;
      text-shadow: -1px 4px 8px rgba(0,0,0,0.43);
    }
  }

  #images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
    .figure {
      position: relative;
      margin: 0.5vh;
      background-size: cover;      
	    overflow: hidden;
      cursor: pointer;
      background: #fffbf6;
      height: 300px;
      width: 300px;
      border-radius: 20px;

      .image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        transform: translate(-50%, -50%);
        z-index: 2;
        opacity: 1.0;
        transition: all 0.5s ease;
        user-select: none;
      }
      .caption {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #222d426b
      }
      .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4vw 4vw;
      }
      
      hr {
        width: 50%;
        border: 0;
        border-bottom: 4px solid rgba(255, 255, 255, 0.445);
      }
      
    }
  }
}

@media only screen and (max-width: 1000px) {
  #gallery {
    padding: 0 !important;
  }
}
@media only screen and (max-width: 661px) {
  .title{
    font-size: 16px !important;
  }
  #gallery {
    #images {
    .figure {
      height: 200px;      
      width: 200px;      

    }
  }
  }
}

@media only screen and (max-width: 451px) {
  .title{
    font-size: 14px !important;
  }
  #gallery {
    #images {
    .figure {
      height: 130px;      
      width: 130px;      
    }
  }
  }
}

.modal-food-title{
  text-align: center;
  color: #EF791F;
  font-size: 30px;

}
.modal-food-description{
  font-weight: 600;
  font-size: 16px;
  color: rgba(100, 100, 100, 0.808);
  text-align: center;
}
.modal-food-image{
  border-radius: 20px;
}
/*DEMO ONLY*/
.service-categories .card{
  transition: all 0.2s;
  background-color: #222D42;
}
.service-categories .card-title{
  padding-top: 0.5em;
}
.service-categories a:hover{
  text-decoration: none;  
}
.service-card{
  background: rgba(72, 88, 104, 0.9);
  border: 0;
}
.service-card:hover{
  background: #fffbf6;
  .title{
    color: #222D42 !important;
  }
}
.card-block{
  align-items: center;
  justify-content: center;
  display: grid;
  cursor: pointer;
}
.card-block .image{
    width: 90px !important;
    height: 90px !important;
    padding: 5px;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
}
.form-check-input:checked {
  background-color: #EF791F !important;
  border-color: #EF791F !important; 
}
