.blyat{

    margin-bottom: 10px;
}
.blyat2{
    background-color: #fffbf6;
    box-shadow: 0px 8px 36px #222;
    font-weight: 400;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius);
    margin: 0;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color:   #DA5C40!important;
    background-color: #fffbf6!important;
}
.Address{
    height: 100vh;
    background-image: url('../../assets/4438226.png'); 
    background-size: cover;
    background-repeat: no-repeat, repeat;
    padding: 0.75rem;
}
.nav-link {
    color:   black!important;
    font-weight: 400;

}
.mobile-col{
    height: 60vh;
    overflow-y: scroll;
    background-color: #fffbf6;
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -webkit-box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
    box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

}
.mobile-col::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
@media only screen and (max-width:768px) {
    .add-col{
        padding-top: 10px;
    }
    .blyat{
        padding: 0!important;
    }
}