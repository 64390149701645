.sidemenu{
 .puan{
    color: #FFA166;
    font-weight: 700 !important;
 }   
 .points{
    color: #DA5C40;
    font-weight: 1000 !important;
 }
 .drop{
   color: #FFA166;
   font-weight: 700 !important;
   text-decoration: none;
 }
 .drop:hover{
   color: #DA5C40;

 }
 .dropdown-item:focus{
  background-color:#DA5C40 ;
 }
}