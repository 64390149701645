.nav-tabs .nav-link:hover,.nav-tabs .nav-link:focus{
    border-color:#626C72 !important;

}
th{
    cursor: pointer;
}
.address-link{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
}
.addresmodal{
    min-height: 130px;
}
#order{
    background-color: #fffbf6;
    box-shadow: 0px 8px 36px #222;
    border-radius: 10px;
}


@media screen and (max-width: 992px) {
    #order{
        padding-right: 0px;
        padding-left: 0px;
        margin-top: 10px;
    }
    .bobber{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    
}
.checkMark{
    fill: #FFA166 !important;
    width: 40px;
    height: 40px;
    
    cursor: pointer;
}
.checkMark path{
    color: #FFA166 !important;

}
.checkMark:hover{
    opacity: 0.8;
}
.star{
    color: #FFA166 !important;
    fill: #FFA166 !important;
    width:30px;
    height: 30px;
    margin-bottom: 5px;
}